export interface PaginationData {
  currentPage: number
  totalPages: number
  totalCount: number
}

export function validatePaginationData(
  paginationData: PaginationData | any,
): asserts paginationData is PaginationData {
  if (
    typeof paginationData.currentPage !== 'number' ||
    isNaN(paginationData.currentPage)
  ) {
    throw new TypeError('PaginationData.currentPage is not a number')
  }
  if (
    typeof paginationData.totalPages !== 'number' ||
    isNaN(paginationData.totalPages)
  ) {
    throw new TypeError('PaginationData.totalPages is not a number')
  }
  if (
    typeof paginationData.totalCount !== 'number' ||
    isNaN(paginationData.totalCount)
  ) {
    throw new TypeError('PaginationData.totalCount is not a number')
  }
  if (paginationData.currentPage < 1) {
    throw new TypeError(
      `PaginationData.currentPage(${paginationData.currentPage}) is less than 1`,
    )
  }
  if (
    paginationData.totalPages > 0 &&
    paginationData.currentPage > paginationData.totalPages
  ) {
    throw new TypeError(
      'PaginationData.currentPage is greather than totalPages',
    )
  }
}
