
import { PaginationData, validatePaginationData } from '~/utility/pagination'
import { Component, Prop, Vue } from '~/utility/pu-class-decorator'
import { reportError } from '~/utility/reportError'

@Component
export default class PaginationControls extends Vue {
  @Prop({
    required: true,
    validator: (v) => {
      try {
        validatePaginationData(v)
        return true
      } catch (err) {
        reportError(err as Error)
        return false
      }
    },
  })
    paginationData: PaginationData

  get currentPage() {
    return this.paginationData.currentPage
  }

  get totalPages() {
    return this.paginationData.totalPages
  }

  get isFirst() {
    return this.currentPage === 1
  }

  get isLast() {
    return this.currentPage === this.totalPages
  }
}
